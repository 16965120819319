import { Plan, ManualInvoiceAdminFee } from '~/types/subscriptions'

const pricing: Record<Plan, number> = {
  [Plan.INDIVIDUAL_MONTHLY_2020]: 8.99,
  [Plan.INDIVIDUAL_ANNUAL_2020]: 89,
  [Plan.INDIVIDUAL_MONTHLY_2022]: 9.99,
  [Plan.INDIVIDUAL_ANNUAL_2022]: 99,
  [Plan.INDIVIDUAL_MONTHLY_2025]: 11.99,
  [Plan.INDIVIDUAL_ANNUAL_2025]: 120,
  [Plan.SCHOOL_ANNUAL_2022]: 99,
  [Plan.SCHOOL_ANNUAL_2025]: 120
}

export const LATEST_INDIVIDUAL_MONTHLY_PLAN = Plan.INDIVIDUAL_MONTHLY_2025
export const LATEST_INDIVIDUAL_ANNUAL_PLAN = Plan.INDIVIDUAL_ANNUAL_2025
export const LATEST_SCHOOL_ANNUAL_PLAN = Plan.SCHOOL_ANNUAL_2025

const adminFee: Record<ManualInvoiceAdminFee, number> = {
  [ManualInvoiceAdminFee.SCHOOL_ANNUAL_2025]: 15
}

export const LATEST_SCHOOL_ANNUAL_ADMIN_FEE = ManualInvoiceAdminFee.SCHOOL_ANNUAL_2025

export const DAYS_UNTIL_INVOICE_DUE = 14

export { pricing, adminFee }
